import auth from '@/router/_middleware/auth'

export default [
  {
    component: () => import('@/views/support/New.vue'),
    meta: { middleware: [auth] },
    name: 'manager.support.new',
    path: '/manager/support/new',
  },
  {
    component: () => import('@/views/support/Item.vue'),
    meta: { middleware: [auth] },
    name: 'manager.support.item',
    path: '/manager/support/:ticketID',
  },
  {
    component: () => import('@/views/support/List.vue'),
    meta: { middleware: [auth] },
    name: 'manager.support',
    path: '/manager/support',
  },
]
