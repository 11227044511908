




































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

// components
import Tag from '@/components/_uikit/Tag.vue'
// types
import AuthModule from '@/store/modules/auth'
import MasterEducationModule from '@/store/modules/master/education'
import DictionaryModule from '@/store/modules/dictionary'
import { EducationDrawerExerciseResource, ExerciseStatus, NameValueResource } from '@/store/types'
// utils
import { parseDateToMilliseconds } from '@/utils/functions'

@Component({
  components: {
    Tag,
  },
})
export default class DrawerExerciseCheckList extends Vue {
  @Prop({ default: [] })
  private drawerItems!: EducationDrawerExerciseResource[]

  private innerItems = this.drawerItems.map(item => {
    return {
      ...item,
      isSpending: this.parseDateToMilliseconds(item.spendingAt) > this.currentTime,
    }
  })

  private get currentTime() {
    return DictionaryModule.currentTime
  }

  private get currentMasterGroupID() {
    return MasterEducationModule.currentMasterGroupID
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private isExcersiseCompleted(item: NameValueResource) {
    return item.value === ExerciseStatus.COMPLETE
  }

  private isExcersiseOnCheck(item: NameValueResource) {
    return item.value === ExerciseStatus.CHECKED || item.value === ExerciseStatus.ON_CHECK
  }

  private isExcersiseWait(item: NameValueResource) {
    return item.value === ExerciseStatus.WAIT || item.value === ExerciseStatus.IN_PROCESS
  }

  private getColor(item: EducationDrawerExerciseResource & { isDeadlinePassed: boolean }) {
    if ((item.lostHeart || item.isDeadlinePassed) && this.isExcersiseWait(item.status)) {
      return 'red'
    } else if (this.isExcersiseCompleted(item.status) || this.isExcersiseOnCheck(item.status)) {
      return 'teal'
    }
    return 'orange'
  }

  private parseDateToMilliseconds(date: string): number {
    return parseDateToMilliseconds(date)
  }

  @Watch('drawerItems')
  private watchDrawerItems() {
    this.innerItems = this.drawerItems.map(item => {
      return {
        ...item,
        isSpending: this.parseDateToMilliseconds(item.spendingAt) > this.currentTime,
      }
    })
  }
}
