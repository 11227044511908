export const tableFooterOptions = {
  'items-per-page-options': [10, 25, 50, 100],
  'items-per-page-text': '',
  'show-current-page': true,
  'show-first-last-page': false,
}

export const alphabet = 'АБВГДЕЖЗИКЛМНОПРСТУФХЦЧШЩЭЮЯ'.split('')
export const alphabetEng = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
export const domainName = 'smitup.ru'

// Время, через сколько будут обновляться таски при возвращении на вкладку, мин
export const updateTaskMinutes = 10

// Время бездействия пользователя, через которое будет обновляться дз по сокетам, мин
export const updateTaskFormMinutes = 0.5

// Новый год. Константа для отображения анимации снежинок и новогоднего лого
export const isNewYear = process.env.VUE_APP_NEW_YEAR === '1'

// Отображение анимации к концу учебного года
export const isShowEndYearAnimation = process.env.VUE_APP_END_YEAR_ANIMATION === '1'

// Отображение анимации к дню рождения
export const isShowBirthdayAnimation = process.env.VUE_APP_BIRTHDAY_ANIMATION === '1'

export const subjectsListOnID: Record<number, string> = {
  1: 'history',
  2: 'social',
  3: 'law-school',
  4: 'olympiad',
  5: 'literature',
  6: 'russian-language',
  7: 'olympiad-social-science',
  8: 'biology',
  9: 'english-language',
  10: 'chemistry',
  11: 'mathematics',
  12: 'physics',
  13: 'mathematics',
}

export const subjectsColor: Record<string, string> = {
  'history': 'orange',
  'social': 'coral',
  'law-school': 'blue',
  'olympiad': 'brown',
  'literature': 'deep-purple',
  'russian-language': 'teal',
  'olympiad-social-science': 'coral',
  'biology': 'green',
  'english-language': 'grey-pink',
  'chemistry': 'purple',
  'mathematics': 'deep-blue',
  'physics': 'indigo',
}

export const thumbs = [
  require('@/assets/images/stub/avatar/1.png'),
  require('@/assets/images/stub/avatar/2.png'),
  require('@/assets/images/stub/avatar/3.png'),
  require('@/assets/images/stub/avatar/4.png'),
  require('@/assets/images/stub/avatar/5.png'),
  require('@/assets/images/stub/avatar/6.png'),
  require('@/assets/images/stub/avatar/7.png'),
  require('@/assets/images/stub/avatar/8.png'),
  require('@/assets/images/stub/avatar/9.png'),
  require('@/assets/images/stub/avatar/10.png'),
  require('@/assets/images/stub/avatar/11.png'),
  require('@/assets/images/stub/avatar/12.png'),
  require('@/assets/images/stub/avatar/11.png'),
]

export const timeout = 30000

export const timeLimit = 3000

export const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone
export const timeZoneOffset = new Date().getTimezoneOffset() / -60 - 3
