import { render, staticRenderFns } from "./NotesDrawer.vue?vue&type=template&id=cc4ac538&scoped=true&lang=pug&"
import script from "./NotesDrawer.vue?vue&type=script&lang=ts&"
export * from "./NotesDrawer.vue?vue&type=script&lang=ts&"
import style0 from "./NotesDrawer.vue?vue&type=style&index=0&id=cc4ac538&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc4ac538",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VCardText,VDivider,VIcon,VNavigationDrawer})
