






































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import vuescroll from 'vuescroll'

// store
import AuthModule from '@/store/modules/auth'
import MasterTutorialsModule from '@/store/modules/master/tutorials'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'

@Component
export default class AppMainTutotrial extends Mixins(NotifyMixin) {
  @Prop()
  private scroll!: vuescroll

  private get positionReset() {
    return this.$vuetify.breakpoint.width < 1025
  }

  private get isMobileSidebar() {
    return this.$vuetify.breakpoint.width < 1366
  }

  private get isTourStart() {
    return MasterTutorialsModule.mainTutorialIsActive
  }

  private steps = [
    {
      before: () => this.handleScrollToTop(),
      content: 'Здесь ты можешь просмотреть <strong>свои курсы.</strong> Настрой их отображение, нажав на иконку шестеренки — убирай курс, если не хочешь видеть его в уроках, дз и календаре.',
      header: {
        title: 'Верхнее меню',
      },
      options: {
        labels: {
          buttonNext: 'Дашборд',
        },
      },
      params: {
        enableScrolling: false,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-16, 32],
            },
          },
        ],
        placement: this.positionReset ? 'bottom' : 'bottom-start',
      },
      target: '#step-1',
    },
    {
      before: () => this.scrollNextStep('#step-2'),
      content: 'В этом блоке собрана вся основная информация: <strong>уроки</strong> и <strong>задания</strong>; <strong>статистика</strong> по курсу и твой <strong>прогресс</strong>; информация о <strong>наставнике</strong> и <strong>чат</strong> группы.',
      header: {
        title: 'Дашборд',
      },
      options: {
        labels: {
          buttonNext: 'Календарь',
          buttonPrevious: 'Верхнее меню',
        },
      },
      params: {
        enableScrolling: false,
        placement: this.positionReset ? 'bottom' : 'right-start',
      },
      target: '#step-2',
    },
    {
      before: () => this.scrollNextStep('#step-3'),
      content: '<p>Календарь — твой карманный <strong>ежедневник</strong>. Здесь отображаются все <strong>дедлайны, дата</strong> и <strong>время уроков</strong> и <strong>даты публикации</strong> материалов из базы знаний.</p><p>Под календарем <strong>за 10 дней</strong> до окончания оплачиваемого периода появится кнопка <strong>Продления курса</strong>.</p>',
      header: {
        title: 'Календарь',
      },
      options: {
        labels: {
          buttonNext: 'Повторение и помощь',
          buttonPrevious: 'Верхнее меню',
        },
      },
      params: {
        enableScrolling: false,
        placement: this.positionReset ? 'bottom' : 'left-start',
      },
      target: '#step-3',
    },
    {
      before: () => this.scrollNextStep('#step-4'),
      content: '<p>На главном дашборде ты найдешь <strong>тест на повторение</strong>. Он состоит из заданий, в которых ты делал ошибки. Реши его, чтобы закрепить полученные знания.</p><p>Также ты можешь перейти в <strong>чат со скорой помощью</strong>. Там тебе подскажут, как выполнить трудные задания (но не сделают их за тебя).</p>',
      header: {
        title: 'Повторение и помощь',
      },
      options: {
        labels: {
          buttonNext: 'Навигация',
          buttonPrevious: 'Календарь',
        },
      },
      params: {
        enableScrolling: false,
        placement: this.positionReset ? 'bottom' : 'top-start',
      },
      target: '#step-4',
    },
    {
      before: () => this.handleScrollToTop(),
      content: '<p>В меню находятся <strong>различные разделы</strong>: Обучение; Домашняя работа; Уроки; Магазин курсов (здесь ты можешь <strong>купить новый курс</strong> или <strong>продлить</strong> текущие); Календарь; База знаний; Техподдержка; Мои достижения.</p><p>Советуем <strong>изучить</strong> каждый из них отдельно!</p>',
      header: {
        title: 'Навигация',
      },
      options: {
        labels: {
          buttonNext: 'Профиль',
          buttonPrevious: 'Повторение и помощь',
        },
      },
      params: {
        enableScrolling: false,
        placement: this.isMobileSidebar ? this.positionReset ? 'bottom' : 'bottom' : 'right-start',
      },
      target: this.isMobileSidebar ? '#step-5-mobile' : '#step-5-desktop',
    },
    {
      before: (type: 'next' | 'prev') => new Promise(resolve => {
        if (type === 'next')
          return resolve(true)
        this.$router.push({
          name: 'master',
        })
          .then(() => {
            resolve(true)
          })
      }),
      content: '<p>В верхнем меню ты видишь Уведомления, Блокнот и Профиль с информацией о тебе и твоих курсах.</p><p>Отдельный раздел – Профиль. В нем хранятся твои данные. Чтобы открыть его, просто <strong>нажми на свою аватарку</strong>.</p>',
      header: {
        title: 'Профиль',
      },
      options: {
        labels: {
          buttonPrevious: 'Навигация',
        },
      },
      params: {
        enableScrolling: false,
        placement: this.positionReset ? 'bottom' : 'bottom-end',
      },
      target: '#step-6',
    },
    {
      before: (type: 'next' | 'prev') => new Promise(resolve => {
        if (type === 'prev')
          return resolve(true)
        this.$router.push({
          name: 'profile',
        })
          .then(() => {
            resolve(true)
          })
      }),
      content: '<p>Здесь хранится твоя личная информация. Ты можешь менять ее в любой момент. Важно привязать свою <strong>настоящую страничку Вконтакте</strong> и указать настоящий <strong>ник в Telegram</strong> (по ним с тобой смогут связаться и добавить в скорую помощь).</p><p>По умолчанию на сайте стоит <strong>светлая тема</strong>. Но здесь ты можешь поменять ее на <strong>темную</strong>.</p><p>Если ты живешь не по московскому времени, ты можешь изменить <strong>отображение времени</strong> проведения <strong>уроков и дедлайнов</strong> по тому, которое <strong>установлено на твоем устройстве!</strong></p>',
      header: {
        title: 'Данные профиля',
      },
      options: {
        labels: {
          buttonNext: 'Оплаты',
          buttonPrevious: 'Профиль',
        },
      },
      params: {
        enableScrolling: false,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [320, 16],
            },
          },
        ],
        placement: this.positionReset ? 'bottom' : 'left-end',
      },
      target: '#step-profile',
    },
    {
      before: (type: 'next' | 'prev') => new Promise(resolve => {
        if (type === 'prev')
          return resolve(true)
        this.$router.push({
          name: 'profile.payments',
        })
          .then(() => {
            resolve(true)
          })
      }),
      content: 'В данном разделе можно найти <strong>все оплаты</strong>, которые были на твоем аккаунте.',
      header: {
        title: 'Оплаты',
      },
      options: {
        labels: {
          buttonNext: 'Курсы',
          buttonPrevious: 'Данные профиля',
        },
      },
      params: {
        enableScrolling: false,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [320, 16],
            },
          },
        ],
        placement: this.positionReset ? 'bottom' : 'left-end',
      },
      target: '#step-profile',
    },
    {
      before: (type: 'next' | 'prev') => new Promise(resolve => {
        if (type === 'prev')
          return resolve(true)
        this.$router.push({
          name: 'profile.courses',
        })
          .then(() => {
            resolve(true)
          })
      }),
      content: 'Здесь собраны данные о всех твоих <strong>курсах, группах</strong> и <strong>наставниках</strong>.',
      header: {
        title: 'Курсы',
      },
      options: {
        labels: {
          buttonNext: 'Уведомления',
          buttonPrevious: 'Оплаты',
        },
      },
      params: {
        enableScrolling: false,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [320, 16],
            },
          },
        ],
        placement: this.positionReset ? 'bottom' : 'left-end',
      },
      target: '#step-profile',
    },
    {
      before: (type: 'next' | 'prev') => new Promise(resolve => {
        if (type === 'prev')
          return resolve(true)
        this.$router.push({
          name: 'profile.notifications',
        })
          .then(() => {
            resolve(true)
          })
      }),
      content: 'На этой странице ты можешь <strong>управлять уведомлениями</strong> на сайте в Telegram по разным категориям: домашние задания, жизни, оплата и тд. Не забудь нажать на кнопку <strong>Сохранить</strong> внизу, чтобы изменения вступили в силу.',
      header: {
        title: 'Уведомления',
      },
      options: {
        labels: {
          buttonPrevious: 'Курсы',
        },
      },
      params: {
        enableScrolling: false,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [320, 16],
            },
          },
        ],
        placement: this.positionReset ? 'bottom' : 'left-end',
      },
      target: '#step-profile',
    },
  ]

  private callbacks = {
    onFinish: this.tourFinish,
    onNextStep: this.onNextStep,
    onPreviousStep: this.onPreviousStep,
    onSkip: this.tourDestroy,
    onStart: this.tourStart,
    onStop: this.tourDestroy,
  }

  private tourStart() {
    this.handleScrollToTop()
    MasterTutorialsModule.setMainTutorialActivity(true)
    MasterTutorialsModule.setMainTutorialStep(1)
  }

  private completeTour() {
    MasterTutorialsModule.setMainTutorialActivity(false)
    MasterTutorialsModule.setMainTutorialStep(null)
    this.$tours['mainTour'].finish()
  }

  private tourFinish() {
    if (AuthModule.self && !AuthModule.self.tutorialPassed) {
      this.$bus.$emit('completeMainTour')
    }
  }

  private tourDestroy() {
    this.fetchCompleteTour()
    MasterTutorialsModule.setMainTutorialActivity(false)
    MasterTutorialsModule.setMainTutorialStep(null)
    const sidenavTour = document.querySelector('.tour-sidebar') as HTMLElement
    sidenavTour?.classList.remove('tour-sidebar--active')
    this.handleScrollToTop()
  }

  private fetchCompleteTour() {
    if (AuthModule.self && !AuthModule.self.tutorialPassed) {
      this.$bus.$emit('showMessage')
      AuthModule.completeTour()
        .catch(this.notifyError)
    }
  }

  private handleScrollToTop() {
    this.scroll.scrollTo({ x:0, y: 0 }, 500, 'easeInOutQuad')

    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: 0,
      })
    }, 450)
  }

  private scrollTop(type: 'next' | 'previous') {
    return new Promise((resolve) => {
      if (type === 'previous') {
        this.scroll.scrollTo({ y: 0 }, 500, 'easeInOutQuad')
        resolve(true)
      } else {
        resolve(true)
      }
    })
  }

  private scrollNextStep(selector: string/*, scrollSidenav = false*/) {
    return new Promise(resolve => {
      const { scrollTop } = this.scroll.getPosition()
      const top = document.querySelector(selector)?.getBoundingClientRect().top || 0
      const y = (scrollTop || 0) + top - 120
      this.scroll.scrollTo({ y }, 500, 'easeInOutQuad')
      resolve(true)
    })
  }

  private startTour() {
    setTimeout(() => {
      this.$tours['mainTour'].start()
    }, 500)
  }

  private onNextStep(step: number) {
    MasterTutorialsModule.setMainTutorialStep(step + 2)
  }

  private onPreviousStep(step: number) {
    MasterTutorialsModule.setMainTutorialStep(step)
  }

  private mounted() {
    this.$bus.$on('startTour', this.startTour)
  }
}
