import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  ISupportFilter, MessageResource,
  MessageStore,
  SupportGetRequest, SupportIdClosePostRequest, SupportIdGetRequest, SupportIdMessagePostRequest, SupportPostRequest,
  TicketLargeResource,
  TicketShortResourcePaginator,
  TicketStore,
} from '@/store/types'
import { tableOptionsToParams } from '@/utils/functions'

/**
 * Работа с техподдержкой
 * - создание тикетов
 * - получение списка тикетов
 * - просмотр, ответ и закрытие тикета
 */

@Module({
  dynamic: true,
  name: 'Support',
  namespaced: true,
  store,
})
class Support extends VuexModule {
  // ---------------------------- Tickets ---------------------------- >>
  // Filter
  ticketsFilter: ISupportFilter = GET_DEFAULT_TABLE_FILTER()

  @Mutation
  setTicketsFilter (payload: ISupportFilter) {
    this.ticketsFilter = Object.assign({}, payload)
  }

  // Entities
  tickets: TicketShortResourcePaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setTickets (payload: TicketShortResourcePaginator) {
    this.tickets = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchTickets(loading = true) {
    const { data } = await SupportGetRequest(tableOptionsToParams(this.ticketsFilter), { loading })

    this.setTickets(data)

    return data
  }

  // ---------------------------- Ticket ---------------------------- >>

  ticket: TicketLargeResource | null = null

  @Mutation
  setTicket (payload: TicketLargeResource) {
    this.ticket = Object.assign({}, payload)
  }

  @Mutation
  addTicketMessage (payload: MessageResource) {
    if (this.ticket && this.ticket.id === payload.parentId) {
      this.ticket.messages.push(payload)
    }
  }

  @Action({ rawError: true })
  async fetchTicket (ticketID: number) {
    const { data } = await SupportIdGetRequest(ticketID)

    this.setTicket(data)

    return data
  }

  @Action({ rawError: true })
  async createTicket (payload: TicketStore) {
    const { data } = await SupportPostRequest(payload)

    return data
  }

  @Action({ rawError: true })
  async sendTicketMessage (payload: { ticketID: number, params: MessageStore }) {
    const { data } = await SupportIdMessagePostRequest(
      payload.ticketID,
      payload.params,
    )

    this.addTicketMessage(data)

    return data
  }

  @Action({ rawError: true })
  async closeTicket (ticketID: number) {
    const { data } = await SupportIdClosePostRequest(ticketID)

    this.setTicket(data)

    return data
  }
}

const SupportModule = getModule(Support)

export default SupportModule
