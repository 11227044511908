




























import { Component, Prop, Vue } from 'vue-property-decorator'

import { UserShortResource } from '@/store/types'
import Hearts from '@/components/Hearts.vue'

@Component({
  components: {
    Hearts,
  },
})
export default class UserCard extends Vue {
  @Prop({ required: true })
  private user!: UserShortResource

  @Prop({ default: 'Без пользователя' })
  private emptyUserMessage!: string

  @Prop({ default: false })
  private onlyName!: boolean

  @Prop({ default: false })
  private showPartyHat!: boolean

  @Prop({ default: false })
  private large!: boolean

  @Prop({ default: '' })
  private hint!: string

  @Prop({ default: null })
  private hearts!: number

  @Prop({ default: false })
  private tealHint!: boolean

  private get username () {
    if (!this.user) {
      return this.emptyUserMessage
    } else if (this.onlyName) {
      return this.user.name
    } else {
      return this.user.surname ? `${this.user.name} ${this.user.surname}` : this.user.name
    }
  }

  private get socialVK() {
    return this.user.socials?.find(item => item.type === 'vkontakte')
  }

  private get socialTG() {
    return this.user.socials?.find(item => item.type === 'telegram')
  }
}
