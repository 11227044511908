import auth from '@/router/_middleware/auth'

export default [
  {
    component: () => import('@/views/master/store/Store.vue'),
    meta: { middleware: [auth] },
    name: 'master.store',
    path: '/master/store',
  },
  {
    component: () => import('@/views/master/store/Item.vue'),
    meta: { middleware: [auth] },
    name: 'master.store.item',
    path: '/master/store/:courseID',
  },
]
