










import { Component, Vue } from 'vue-property-decorator'

import { DocsEnum } from '@/store/types/docs'

@Component
export default class CookieAgreement extends Vue {
  readonly text = 'Продолжая пользоваться сайтом, вы соглашаетесь c <a href="/files/PD_policy.pdf" target="_blank">условиями использования файлов cookie.</a>'
  private isAccept = true

  private docsLink = {
    offerEytutis: DocsEnum.OFFER_EYTUTIS,
    offerMalyshev: DocsEnum.OFFER_MALYSHEV,
    offerRogovenko: DocsEnum.OFFER_ROGOVENKO,
    offerSharafieva: DocsEnum.OFFER_SHARAFIEVA,
    offerShvetsov: DocsEnum.OFFER_SHVETSOV,
    offerStrelkova: DocsEnum.OFFER_STRELKOVA,
    offerVolvaka: DocsEnum.OFFER_VOLVAKA,
    offerYakupova: DocsEnum.OFFER_YAKUPOVA,
    personal: DocsEnum.PERSONAL,
    policy: DocsEnum.POLICY,
  }

  mounted () {
    if (localStorage.getItem('acceptCookies') !== '1') {
      this.isAccept = false
    }
  }

  protected handleAccept () {
    localStorage.setItem('acceptCookies', '1')
    this.isAccept = true
  }
}
