























import { Component, Mixins, Watch } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
// components
import DrawerLessonsCheckList from '@/components/layout/drawers/DrawerLessonsCheckList.vue'
// store
import MasterLessonsModule from '@/store/modules/master/lessons'
// types
import { MasterLessonDrawerResource } from '@/store/types'
import DetectSafariMixin from '@/mixins/DetectSafariMixin'

@Component({
  components: {
    DrawerLessonsCheckList,
  },
})
export default class LessonsDrawer extends Mixins(MasterGroupMixin, NotifyMixin, DetectSafariMixin) {
  private get visibility () {
    return MasterLessonsModule.drawerVisibility
  }

  private get drawerLessons() {
    return MasterLessonsModule.drawerLessons
  }

  private get lessonsFilter() {
    return MasterLessonsModule.lessonsFilter
  }

  private get stringFilter() {
    return JSON.stringify(this.lessonsFilter)
  }

  private get checkedLessons() {
    return this.drawerLessons.reduce((acc: number, cur: MasterLessonDrawerResource) => {
      return cur.checkedAt ? acc + 1 : acc
    }, 0)
  }

  private fetchDrawerLessons() {
    MasterLessonsModule.fetchDrawerLessons(this.currentMasterGroupID)
      .catch(this.notifyError)
  }

  private handleInputDrawer(value: boolean) {
    MasterLessonsModule.setDrawerVisibility(value)
  }

  @Watch('visibility')
  watchVisibility(value: boolean) {
    if (value) {
      this.fetchDrawerLessons()
    }
  }

  @Watch('stringFilter')
  watchFilter() {
    if (this.visibility) {
      this.fetchDrawerLessons()
    }
  }
}
