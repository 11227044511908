import { Component } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// types
import { AuthByTgRequest } from '@/store/types'
import AuthModule from '@/store/modules/auth'
import ProfileModule from '@/store/modules/profile'
import MasterEducationModule from '@/store/modules/master/education'

interface ITelegramUserResponse {
  auth_date: number,
  first_name: string,
  hash: string,
  id: number,
  last_name: string,
  photo_url: string,
  username: string,
}
@Component
export default class TelegramMixin extends NotifyMixin {
  private botName = process.env.VUE_APP_TELEGRAM_BOT_NAME
  private botID = process.env.VUE_APP_TELEGRAM_BOT_ID

  protected handlePatchTelegram() {
    // При добавлении виджета на страницу, Телеграм создает объект в Window, у которого есть метод авторизации пользователя
    // any добавил, чтобы TypeScript не ругался на Telegram.Login

    window.Telegram.Login.auth(
      { bot_id: this.botID, request_access: 'write' },
      (date: ITelegramUserResponse) => {
        this.attachTelegramProfile(date)
      },
    )
  }

  protected attachTelegramProfile(user: ITelegramUserResponse) {
    if(user) {
      const payload: AuthByTgRequest = {
        id: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        username: user.username,
        photoUrl: user.photo_url,
        authDate: user.auth_date,
        hash: user.hash,
      }

      AuthModule.patchTG(payload)
        .then(() => {
          ProfileModule.fetchInformation()
            .then(response => {
              this.$bus.$emit('setProfileForm', response)
              AuthModule.getUser()
                .then(response => {
                  this.notifySuccess('Telegram успешно привязан')
                  if (response.hasSocials) {
                    MasterEducationModule.fetchMasterGroups()
                      .catch(this.notifyError)
                  }
                })
            })
            .catch(this.notifyError)
        })
        .catch(this.notifyError)
    } else {
      this.$bus.$emit('telegramProblem')
    }
  }
}
