


















import { Component, Prop } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import { MasterLessonDrawerResource } from '@/store/types'
import Tag from '@/components/_uikit/Tag.vue'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    Tag,
  },
})
export default class DrawerLessonsCheckList extends NotifyMixin {
  @Prop({ default: [] })
  private lessons!: MasterLessonDrawerResource[]

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }
}
