


































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// components
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import ImageModal from '@/components/_uikit/editor/tiptap/ImageModal.vue'
import LinkModal from '@/components/_uikit/editor/tiptap/LinkModal.vue'
import TableModal from '@/components/_uikit/editor/tiptap/TableModal.vue'
// store
import EditorModule from '@/store/modules/editor'

@Component({
  components: {
    ButtonTextIcon,
    ImageModal,
    LinkModal,
    TableModal,
  },
})
export default class TiptapToolbar extends Vue {
  @Prop({ required: true })
  private editor!: any

  @Prop({ default: false })
  private isPreview!: boolean

  @Prop({ default: false })
  private isBubble!: boolean

  @Prop({ default: 0 })
  private lines!: number

  @Prop({ default: false })
  private toolbarCollapse!: boolean

  @Prop({ default: '' })
  private color!: string

  @Prop({ default: false })
  private showChooseColor!: boolean

  @Prop({ default: '' })
  private marker!: string

  @Prop({ default: false })
  private showChooseMarker!: boolean

  @Prop({ default: false })
  private showChooseTable!: boolean

  @Prop({ default: false })
  private showBubbleChooseColor!: boolean

  @Prop({ default: false })
  private showBubbleChooseMarker!: boolean

  @Prop({ default: false })
  private showBubbleChooseTable!: boolean

  // Отображение кнопки "Показать/скрыть всё"
  @Prop({ default: false })
  private isShowButtonCollapse!: boolean

  @Prop({ default: () => ([]) })
  private showButtonList!: string[]

  private get isEmptyButtonList() {
    return !this.showButtonList.length
  }

  // color
  private colors = ['#ff922e', '#468ae6', '#60aaa9', '#e53935', '#a66eff', '#f25e94']

  // marker
  private markers = ['#ffe4cb', '#d3e2ff', '#daeff0', '#ffcdd2', '#e9dfff', '#eee', '#fff5c1', '#ffd9eb']

  // table
  private showTableModal = false

  // link
  private showLinkModal = false
  private currentLink = ''
  private target = true

  // image
  private showImageModal = false
  private imageAttr = {
    alt: '',
    height: '',
    url: '',
    width: '',
  }

  // Координаты кнопок, которые вызывают дополнительное меню
  // нужно для адаптива дополнительного меню на мобилках
  private colorBtnRect: DOMRect | null = null
  private markerBtnRect: DOMRect | null = null
  private tableBtnRect: DOMRect | null = null

  // Ширина элементов дополнительного меню
  private colorMenuWidth = 0
  private markerMenuWidth = 0
  private tableMenuWidth = 0

  private windowWidth = window.innerWidth

  private mounted() {
    // Если изначально ширина экрана меньше 769px, тогда нужно вызвать методы для адаптива дополнительных меню (цвет, маркер, таблица)
    if (this.windowWidth < 769) {
      this.setRectOnResize()
    }
    window.addEventListener('resize', this.resizeWindow)
  }

  private destroyed() {
    window.removeEventListener('resize', this.resizeWindow)
  }

  private resizeWindow() {
    this.windowWidth = window.innerWidth
    if (this.windowWidth < 769) {
      this.setRectOnResize()
      this.setPositionColorMenu()
      this.setPositionMarkerMenu()
      this.setPositionMarkerMenu()
    } else {
      this.setPositionColorMenu(true)
      this.setPositionMarkerMenu(true)
      this.setPositionMarkerMenu(true)
    }
  }

  private setRectOnResize() {
    if (this.$refs.color)
      this.colorBtnRect = (this.$refs.color as HTMLDivElement).getBoundingClientRect()
    if (this.$refs.marker)
      this.markerBtnRect = (this.$refs.marker as HTMLDivElement).getBoundingClientRect()
    if (this.$refs.table)
      this.tableBtnRect = (this.$refs.table as HTMLDivElement).getBoundingClientRect()
  }

  private handleClickChooseColor() {
    this.$emit('clickChooseColor')
    setTimeout(() => {
      if (this.$refs.colorMenu && this.windowWidth < 769) {
        this.colorMenuWidth = (this.$refs.colorMenu as HTMLDivElement).offsetWidth
        this.setPositionColorMenu()
      }
    })
  }

  private handleClickChooseMarker() {
    this.$emit('clickChooseMarker')
    setTimeout(() => {
      if (this.$refs.markerMenu && this.windowWidth < 769) {
        this.markerMenuWidth = (this.$refs.markerMenu as HTMLDivElement).offsetWidth
        this.setPositionMarkerMenu()
      }
    })
  }

  private handleClickChooseTable() {
    this.$emit('clickChooseTable')
    setTimeout(() => {
      if (this.$refs.tableMenu && this.windowWidth < 769) {
        this.tableMenuWidth = (this.$refs.tableMenu as HTMLDivElement).offsetWidth
        this.setPositionTableMenu()
      }
    })
  }

  private setPositionColorMenu(reset = false) {
    if (this.colorBtnRect && this.$refs.colorMenu)
      (this.$refs.colorMenu as HTMLDivElement).style.left = reset ? '' : `${(this.windowWidth - this.colorMenuWidth) / 2 - this.colorBtnRect.left}px`
  }

  private setPositionMarkerMenu(reset = false) {
    if (this.markerBtnRect && this.$refs.markerMenu)
      (this.$refs.markerMenu as HTMLDivElement).style.left = reset ? '' : `${(this.windowWidth - this.markerMenuWidth) / 2 - this.markerBtnRect.left}px`
  }

  private setPositionTableMenu(reset = false) {
    if (this.tableBtnRect && this.$refs.tableMenu)
      (this.$refs.tableMenu as HTMLDivElement).style.left = reset ? '' : `${(this.windowWidth - this.tableMenuWidth) / 2 - this.tableBtnRect.left}px`
  }

  private chooseColor(color: string) {
    color ? this.$emit('setColor', color) : this.$emit('unsetColor')
  }

  private chooseMarker(marker: string) {
    marker ? this.$emit('setHighlight', marker) : this.$emit('unsetHighlight')
  }

  private openModalLink() {
    this.currentLink = this.editor.getAttributes('link').href
    this.target = this.editor.getAttributes('link').target
    this.showLinkModal = true
  }

  private openTableModal() {
    this.showTableModal = true
    this.$emit('hideChooseTable')
  }

  private openModalImage() {
    if (this.editor.isActive('image')) {
      const attr = this.editor.getAttributes('image')
      this.imageAttr = {
        alt: attr.alt || '',
        height: attr.height || '',
        url: attr.src || '',
        width: attr.width || '',
      }
    } else {
      this.imageAttr = {
        alt: '',
        height: '',
        url: '',
        width: '',
      }
    }
    this.showImageModal = true
  }

  @Watch('showLinkModal')
  private watchShowLinkModal(value: boolean) {
    EditorModule.setFocusMenu({ key: 'linkModal', value })
  }

  @Watch('showImageModal')
  private watchShowImageModal(value: boolean) {
    EditorModule.setFocusMenu({ key: 'imageModal', value })
  }

  @Watch('showTableModal')
  private watchShowTableModal(value: boolean) {
    EditorModule.setFocusMenu({ key: 'tableModal', value })
  }
}
