




















import { ValidationProvider } from 'vee-validate'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { DocsEnum } from '@/store/types/docs'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class Agreement extends Vue {
  @PropSync('value', { required: true })
  private innerValue!: boolean

  @Prop({ default: 'policy' })
  private type!: 'policy' | 'payment'

  @Prop({ default: false })
  private noConfidentialLink!: boolean

  private docsLink = {
    offerBabich: DocsEnum.OFFER_BABICH,
    offerEytutis: DocsEnum.OFFER_EYTUTIS,
    offerMalyshev: DocsEnum.OFFER_MALYSHEV,
    offerRogovenko: DocsEnum.OFFER_ROGOVENKO,
    offerSharafieva: DocsEnum.OFFER_SHARAFIEVA,
    offerShvetsov: DocsEnum.OFFER_SHVETSOV,
    offerStrelkova: DocsEnum.OFFER_STRELKOVA,
    offerVolvaka: DocsEnum.OFFER_VOLVAKA,
    offerYakupova: DocsEnum.OFFER_YAKUPOVA,
    personal: DocsEnum.PERSONAL,
    policy: DocsEnum.POLICY,
    terms: DocsEnum.USER_TERMS,
  }
}
