import auth from '@/router/_middleware/auth'
// ToDo Вернуться и прменять права
import { manageDiscounts } from '@/router/_middleware/manageCommerce'

export default [
  {
    component: () => import('@/views/manager/commerce/discount/New.vue'),
    meta: { middleware: [auth, manageDiscounts] },
    name: 'manager.commerce.discount.item.new',
    path: '/manager/commerce/discounts/new',
  },
  {
    component: () => import('@/views/manager/commerce/discount/Item.vue'),
    meta: { middleware: [auth, manageDiscounts] },
    name: 'manager.commerce.discount.item',
    path: '/manager/commerce/discounts/:saleID',
  },
  {
    component: () => import('@/views/manager/commerce/discount/List.vue'),
    meta: { middleware: [auth, manageDiscounts] },
    name: 'manager.commerce.discount',
    path: '/manager/commerce/discounts',
  },
]
