import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { DateTime } from 'luxon'

import { messages } from './vee-validate-ru.json'

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule],
  })
})

extend('required', {
  computesRequired: true,
  validate (value) {
    return {
      required: true,
      valid: (!Array.isArray(value) && ['', false, null, undefined].indexOf(value) === -1) || (Array.isArray(value) && value.length !== 0),
    }
  },
})

extend('requiredSelect', {
  computesRequired: true,
  message: 'Выберите значение из списка',
  validate (value) {
    return {
      required: true,
      valid: (!Array.isArray(value) && ['', false, null, undefined, 0].indexOf(value) === -1) || (Array.isArray(value) && value.length !== 0),
    }
  },
})

extend('requiredHTML', {
  computesRequired: true,
  message: 'Обязательное поле',
  validate (value) {
    return {
      required: true,
      valid: value.replace(/<[^>]*>?/gm, '') !== '',
    }
  },
})

extend('password', {
  message: 'Пароли не совпадают',
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
})

extend('phone', {
  message: 'Телефон не соответствует формату: 89#########',
  validate: (value) => {
    return /^89\d{9}$/m.test(value)
  },
})

extend('url', {
  message: 'Ссылка должна иметь вид: https://**.*',
  validate: (value) => {
    return /^https?:\/\/.*$/m.test(value)
  },
})

extend('dateTimeMin', {
  params: ['date', 'isCheck'],
  validate(value, { date, isCheck }) {
    if (isCheck) {
      if (date) {
        const valueTS = new Date(value).getTime()
        const dateTS = new Date(date).getTime()
        return valueTS > dateTS || `Дата и время должны быть позже ${DateTime.fromSQL(date).toFormat('dd MMM yyyy, HH:mm')}`
      }
      return true
    }
    return true
  },
})

extend('dateTimeMax', {
  params: ['date', 'isCheck'],
  validate(value, { date, isCheck }) {
    if (isCheck) {
      if (date) {
        const valueTS = new Date(value).getTime()
        const dateTS = new Date(date).getTime()
        return valueTS < dateTS || `Дата и время должны быть раньше ${DateTime.fromSQL(date).toFormat('dd MMM yyyy, HH:mm')}`
      }
      return true
    }
    return true
  },
})
