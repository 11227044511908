import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=4a1957c4&scoped=true&lang=pug&"
import script from "./Header.vue?vue&type=script&lang=ts&"
export * from "./Header.vue?vue&type=script&lang=ts&"
import style0 from "./Header.vue?vue&type=style&index=0&id=4a1957c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a1957c4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VAppBar,VBadge,VBtn,VIcon,VSwitch})
