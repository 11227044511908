








































import { Component, Mixins } from 'vue-property-decorator'

// components
import ButtonIconAction from '@/components/_uikit/buttons/ButtonIconAction.vue'
import NoteCreate from '@/components/notes/NoteCreate.vue'
import NoteItem from '@/components/notes/NoteItem.vue'
import NotesList from '@/components/notes/NotesList.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import DetectSafariMixin from '@/mixins/DetectSafariMixin'
// store
import SystemModule from '@/store/modules/system'
import { NotesDrawerView, UpdateNoteRequest } from '@/store/types'
import MasterNotesModule from '@/store/modules/master/notes'

@Component({
  components: {
    ButtonIconAction,
    NoteCreate,
    NoteItem,
    NotesList,
  },
})
export default class NotesDrawer extends Mixins(NotifyMixin, DetectSafariMixin) {
  private get visibility() {
    return SystemModule.notesVisibility
  }

  private get isCollapse() {
    return SystemModule.isCollapse
  }

  private get isCollapseAccess() {
    return this.$vuetify.breakpoint.width >= 800
  }

  private get title() {
    if (this.view === NotesDrawerView.CREATE)
      return 'Создание заметки'
    if (this.view === NotesDrawerView.EDIT)
      return 'Редактирование заметки'
    return 'Заметки'
  }

  private view: NotesDrawerView = NotesDrawerView.LIST

  private handleClose() {
    SystemModule.hideDrawer()
  }

  private handleCollpase() {
    SystemModule.toggleCollapse()
  }

  private createNote() {
    this.view = NotesDrawerView.CREATE
  }

  private noteCreated() {
    this.view = NotesDrawerView.NOTE
  }

  private handleBack() {
    if (this.view === NotesDrawerView.EDIT) {
      this.view = NotesDrawerView.NOTE
      return
    }
    this.view = NotesDrawerView.LIST
  }

  private toCard(id: number) {
    this.fetchNote(id)
  }

  private fetchNote(id: number) {
    MasterNotesModule.fetchNote(id)
      .then(() => {
        this.view = NotesDrawerView.NOTE
      })
      .catch(this.notifyError)
  }

  private editNote() {
    this.view = NotesDrawerView.EDIT
  }

  private updateNote(note: UpdateNoteRequest) {
    MasterNotesModule.updateNote(note)
      .then(() => {
        this.view = NotesDrawerView.NOTE
      })
      .catch(this.notifyError)
  }

  private saveNote(note: UpdateNoteRequest) {
    MasterNotesModule.updateNote(note)
      .then(() => {
        this.view = NotesDrawerView.LIST
      })
      .catch(this.notifyError)
  }

  private deleteNote(id: number) {
    MasterNotesModule.deleteNote(id)
      .then(() => {
        this.view = NotesDrawerView.LIST
        this.$bus.$emit('deleteNote')
      })
      .catch(this.notifyError)
  }

  private handleInputDrawer(value: boolean) {
    SystemModule.setDrawerVisibility(value)
  }
}
