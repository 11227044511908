import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import {
  MasterEducationMasterGroupIdLessonsGetRequest,
  MasterLessonDrawerResource,
  MasterEducationMasterGroupIdLessonsLessonIdGetRequest,
  MasterLessonLargeResource,
  MasterEducationMasterGroupIdLessonsLessonIdCheckPostRequest,
  MasterEducationMasterGroupIdLessonsLessonIdRatePostRequest,
  MasterLessonRateRequest,
  MasterEducationMasterGroupIdLessonsDrawerGetRequest, MasterEducationMasterGroupIdLessonsGetParams, MasterLessonShortResource,
} from '@/store/types'
import store from '@/store'
import { baseURL, getToken } from '@/utils/services/config'
import SystemModule from '@/store/modules/system'

const { detect } = require('detect-browser')

/**
 * Уроки мастера
 * - todo
 */

@Module({
  dynamic: true,
  name: 'MasterLessons',
  namespaced: true,
  store,
})
class MasterLessons extends VuexModule {
  // ---------------------------- Lessons drawer ---------------------------- >>
  // Visibility
  drawerVisibility = false

  @Mutation
  toggleDrawerVisibility () {
    this.drawerVisibility = !this.drawerVisibility
  }

  @Mutation
  hideDrawer () {
    this.drawerVisibility = false
  }

  @Mutation
  setDrawerVisibility(value: boolean) {
    this.drawerVisibility = value
  }

  // Entities
  drawerLessons: MasterLessonDrawerResource[] = []
  lessonsFilter: MasterEducationMasterGroupIdLessonsGetParams = {}

  @Mutation
  setDrawerLessons (payload: MasterLessonDrawerResource[]) {
    this.drawerLessons = [...payload]
  }

  @Action({ rawError: true })
  async fetchDrawerLessons (masterGroupID: number) {
    const { data } = await MasterEducationMasterGroupIdLessonsDrawerGetRequest(masterGroupID, this.lessonsFilter)
    this.setDrawerLessons(data)
    return data
  }

  // ---------------------------- Lessons ---------------------------- >>
  lessons: MasterLessonShortResource[] = []

  @Mutation
  setLessons (payload: MasterLessonShortResource[]) {
    this.lessons = [...payload]
  }

  @Mutation
  setLessonsFilter (payload: MasterEducationMasterGroupIdLessonsGetParams) {
    this.lessonsFilter = payload
  }

  @Action({ rawError: true })
  async fetchLessons (payload: { masterGroupID: number, params: MasterEducationMasterGroupIdLessonsGetParams}) {
    const { data } = await MasterEducationMasterGroupIdLessonsGetRequest(payload.masterGroupID, payload.params)
    this.setLessons(data)
    return data
  }

  // ---------------------------- Lesson ---------------------------- >>

  lesson: MasterLessonLargeResource | null = null

  @Mutation
  setLesson (payload: MasterLessonLargeResource) {
    this.lesson = Object.assign({}, payload)
  }

  @Mutation
  setLessonTimecode(payload: number) {
    if (this.lesson)
      this.lesson = {
        ...this.lesson,
        playbackMarker: payload,
      }
  }

  @Action({ rawError: true })
  async fetchLesson (payload: { masterGroupID: number, lessonID: number }) {
    const { data } = await MasterEducationMasterGroupIdLessonsLessonIdGetRequest(payload.masterGroupID, payload.lessonID)
    // Проверяем, есть ли запрошенный урок в localStorage
    // если есть, сравниваем таймштамп с тем, который пришёл с бэка
    const lessonStorage = localStorage.getItem(`save-timecode-${payload.lessonID}-${payload.masterGroupID}`)
    const lessonStorageParsed: { seconds: number, ts: number } | null = lessonStorage ? JSON.parse(lessonStorage) : null
    if (lessonStorageParsed) {
      if (data.playbackTimestamp && lessonStorageParsed.ts > data.playbackTimestamp * 1000) {
        this.setLesson({
          ...data,
          playbackMarker: lessonStorageParsed.seconds,
        })
      } else {
        this.setLesson(data)
        localStorage.removeItem(`save-timecode-${payload.lessonID}-${payload.masterGroupID}`)
      }
    } else {
      this.setLesson(data)
    }
    return data
  }

  // ---------------------------- Lesson manage ---------------------------- >>
  @Action({ rawError: true })
  async checkLesson (payload: { masterGroupID: number, lessonID: number }) {
    const { data } = await MasterEducationMasterGroupIdLessonsLessonIdCheckPostRequest(payload.masterGroupID, payload.lessonID)
    this.setLesson(data)
    return data
  }

  @Action({ rawError: true })
  async rateLesson (payload: { masterGroupID: number, lessonID: number, params: MasterLessonRateRequest }) {
    const { data } = await MasterEducationMasterGroupIdLessonsLessonIdRatePostRequest(payload.masterGroupID, payload.lessonID, payload.params)
    this.setLesson(data)
    return data
  }


  previousMasterGroupID: number | null = null

  @Mutation
  setPreviousMasterGroupID(payload: number) {
    this.previousMasterGroupID = payload
  }

  // Сохранение времени просмотра видео урока мастером
  @Action({ rawError: true })
  async saveTimecodeLesson(payload: { masterGroupID: number, lessonID: number, seconds: number, unload: boolean }) {
    const seconds = Math.floor(payload.seconds)

    if (detect().name === 'firefox' && payload.unload) {
      const req = new XMLHttpRequest()
      req.open('POST', `${baseURL}/master/education/${payload.masterGroupID}/lessons/${payload.lessonID}/mark`, false)
      if (process.env.NODE_ENV !== 'production' && getToken()) {
        req.setRequestHeader('Authorization', `Bearer ${getToken()}`)
      }
      req.setRequestHeader('Content-Type', 'application/json')
      req.setRequestHeader('User-Type', `${SystemModule.interface}`)
      req.send(JSON.stringify({ seconds }))
    } else {
      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        'User-Type': SystemModule.interface,
      }
      if (process.env.NODE_ENV !== 'production' && getToken()) {
        headers['Authorization'] = `Bearer ${getToken()}`
      }
      const result = await fetch(`${baseURL}/master/education/${payload.masterGroupID}/lessons/${payload.lessonID}/mark`, {
        body: JSON.stringify({
          seconds,
        }),
        headers,
        keepalive: true,
        method: 'POST',
      })
      if (result.ok && this.lesson && this.lesson.lesson && payload.lessonID === this.lesson.lesson.id) {
        this.setLessonTimecode(seconds)
      }
      return result
    }
  }
}

const MasterLessonsModule = getModule(MasterLessons)

export default MasterLessonsModule
