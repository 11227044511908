























import { Component, Mixins, Watch } from 'vue-property-decorator'
import { sortBy } from 'lodash'

// mixins
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import MasterExercisesModule from '@/store/modules/master/exercises'
// types
import { EducationDrawerExerciseResource, ExerciseStatus } from '@/store/types'
// components
import DrawerExerciseCheckList from '@/components/layout/drawers/DrawerExerciseCheckList.vue'
import { parseDateToMilliseconds } from '@/utils/functions'
import DetectSafariMixin from '@/mixins/DetectSafariMixin'

@Component({
  components: {
    DrawerExerciseCheckList,
  },
})
export default class ExercisesDrawer extends Mixins(MasterGroupMixin, NotifyMixin, DetectSafariMixin) {
  private get visibility () {
    return MasterExercisesModule.drawerVisibility
  }

  private get drawerExercises() {
    return sortBy(MasterExercisesModule.drawerExercises, (item: EducationDrawerExerciseResource) => parseDateToMilliseconds(item.deadlineAt))
  }

  private get drawerFilterString() {
    return JSON.stringify(MasterExercisesModule.exerciseContainersFilter)
  }

  private get completedTasks() {
    return this.drawerExercises.reduce((acc: number, cur: EducationDrawerExerciseResource) => {
      return cur.status.value === ExerciseStatus.COMPLETE || cur.status.value === ExerciseStatus.CHECKED || cur.status.value === ExerciseStatus.ON_CHECK ? acc + 1 : acc
    }, 0)
  }

  private fetchExercises() {
    MasterExercisesModule.fetchDrawerExercises(this.currentMasterGroupID)
      .catch(this.notifyError)
  }

  private handleInputDrawer(value: boolean) {
    MasterExercisesModule.setDrawerVisibility(value)
  }

  @Watch('visibility')
  watchVisibility(value: boolean) {
    if (value) {
      this.fetchExercises()
    }
  }

  @Watch('drawerFilterString')
  watchDrawerFilter() {
    if (this.visibility) {
      this.fetchExercises()
    }
  }
}
